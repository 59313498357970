import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// 导入你的翻译文件
import translationCN from '../i18n/cn/translation.json';
import translationEN from '../i18n/en/translation.json';
import translationJP from '../i18n/jp/translation.json';

i18n
    .use(initReactI18next) // 传递i18n实例给react-i18next
    // 自动检测用户的语言环境
    .use(LanguageDetector)
    .init({
        resources: {
            en: {
                translation: translationEN,
            },
            cn: {
                translation: translationCN,
            },
            jp: {
                translation: translationJP,
            },
        },
        // 默认语言设置为中文
        // lng: 'en',
        fallbackLng: 'cn', // 指定当检测的语言不可用时的后备语言
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['cookie', 'localStorage'], // 配置在哪里缓存用户的语言选择
        },
        interpolation: {
            escapeValue: false, // 不需要对结果进行HTML转义
        },
    });

export default i18n;
