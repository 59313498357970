import React, { Suspense, lazy } from 'react';
import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Loading from './components/Loading/Loading';
// 懒加载组件
const Header = lazy(() => import('./components/Header/Header'));
const Footer = lazy(() => import('./components/Footer/Footer'));

// 路由组件
const Home = lazy(() => import('./routers/HomeRouter/HomeRouter'));
const About = lazy(() => import('./routers/AboutRouter/AboutRouter'));
const Contact = lazy(() => import('./routers/ContacRouter/ContacRouter'));
const Support = lazy(() => import('./routers/SupportRouter/SupportRouter'));
const Services = lazy(() => import('./routers/ServicesRouter/ServicesRouter'));
const FAQ = lazy(() => import('./routers/FAQRouter/FAQRouter'));
const Privacy = lazy(() => import('./routers/PrivacyRouter/PrivacyRouter'));
const NotFound = lazy(() => import('./routers/NotFoundRouter/NotFoundRouter'));

// 页面组件
const PsychologyConsulting = lazy(() => import('./pages/ServicesPages/psychology-consulting'));
const PersonalityTest = lazy(() => import('./pages/ServicesPages/personality-test'));


const App = () => {
  return (
    <div className="app-container">
      <Router>

        <Header />
        <div className="content">
          <Suspense fallback={<Loading />}>
            <Routes>
              {/* 菜单栏路由 */}
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/support" element={<Support />} />
              <Route path="/services" element={<Services />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/contact" element={<Contact />} />
              {/* 服务页面子路由 */}
              <Route path="/services/psychology-consulting" element={<PsychologyConsulting />} />
              <Route path="/services/personality-test" element={<PersonalityTest />} />
              {/* 404 Page */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </div>
        <Footer />

      </Router>
    </div>
  );
}

export default App;
