import { motion } from 'framer-motion';
import React from 'react';
import './Loading.scss';

const Loading = () => {
  return (
    <div className="loading-container">
      <motion.div
        className="loading-spinner"
        initial={{ opacity: 0.5 }}
        animate={{ rotate: 360 }}
        transition={{
          loop: Infinity,
          ease: "linear",
          duration: 1
        }}
        style={{
          border: "4px solid rgba(0, 0, 0, 0.1)",
          borderTop: "4px solid #000",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
        }}
      />
    </div>
  );
};

export default Loading;
